@import 'constants';

.container {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 15px;

  .checkBox {
    background-color: transparent;
    border: 1px solid black;
    height: 16px;
    width: 16px;

    &:hover {
      cursor: pointer;
    }

    &.checked {
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 10px;
      text-align: center;
      background-color: $rove-blue;
    }

    &.secondary {
      background-color: white;
      border: 1px solid $rove-black;

      svg path {
        stroke: #1d1919;
      }
    }
  }

  label {
    font-size: 14px;
    font-weight: 300;
    margin-left: 5px;

    &:hover {
      cursor: pointer;
    }
  }
}
