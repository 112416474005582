@import 'constants';
@import 'mixins';

.arrowButton {
  display: flex;
  flex-direction: row;
  color: $rove-black;

  @include media($sm...) {
    font-size: 14px;
  }
}

.arrowButton:hover {
  color: $rove-blue;
  cursor: pointer;
}

.arrowLeft {
  display: flex !important;
  align-items: center;
  margin-right: 5px;
  font-size: x-large;
}

.arrowRight {
  display: flex !important;
  align-items: center;
  margin-left: 5px;
  font-size: x-large;
}
