@import 'constants';
@import 'mixins';

.container {
  display: flex;
  flex-direction: column;
  margin: 1vh 0;
  font-family: Poppins;

  span {
    font-size: 12px;
    margin-bottom: 3px;
  }

  .input {
    border: 1px solid $rove-dark-black;
    padding: 1vh 1vw;

    @include media($sm...) {
      padding: 1.5vh 3vw;
      background: rgba(255, 255, 255, 0.5);
      border: 0.5px solid $rove-green;

      &::placeholder {
        font-size: 12px;
        line-height: 14px;
        color: $rove-tan;
      }
    }

    &:focus-visible {
      outline: 0;
    }
  }

  .error {
    min-height: 20px;

    li {
      color: red;
      font-weight: 300;
      font-size: 11px;
    }
  }
}
