@import 'constants';
@import 'mixins';

.selectContainer {
  display: flex;
  width: 100%;
  align-items: center;

  @include media($sm...) {
    padding: 2vh 2vw 2vh 2vw;
    flex-direction: column;
    border: 0.5px solid $rove-green;
    align-items: flex-start;
    background-color: rgba(255, 255, 255, 0.5);

    span {
      padding: 0 10px 5px 10px;
      font-size: 12px;
      font-weight: 300;
      line-height: 14px;
    }
  }

  &.secondary {
    border-color: white;

    @include media($sm...) {
      padding: 7px 20px 7px 7px;
      border: 0.5px solid $rove-green;
      background: rgba(248, 244, 241, 0.5);

      span {
        font-weight: 600;
      }
    }
  }

  .icon {
    flex: 2;
    font-size: 20px;
  }

  .mobileButton {
    width: 100%;

    &.secondary {
      color: $rove-black;
      .placeholder {
        font-size: 12px;
        color: $rove-black;
        font-weight: 300;
      }
    }

    .placeholder {
      margin: 0.5vh 0;
      font-size: 12px;
      color: $rove-tan;
      font-weight: 300;
    }

    .value {
      font-weight: 500;
      font-size: 14px;
      line-height: 14px;
    }
  }
}

.mobileSelector {
  position: fixed;
  width: 100%;
  background-color: $rove-off-white;
  top: 0;
  bottom: 0;
  left: 0;
  z-index: $menu-z-index;

  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 2vh 3vw;

    .backButton {
      display: flex;
      align-items: center;
      font-size: 12px;
      line-height: 12px;
      font-weight: 400;

      span {
        display: flex;
      }
    }

    .clearButton {
      font-size: 12px;
      line-height: 14px;
      color: $rove-light-blue;
    }
  }

  .body {
    margin-top: 2vh;

    .searchBar {
      margin: 0 3vw;
    }

    .list {
      margin: 1vh 5vw 0 5vw;
      height: 76vh;
      overflow-y: scroll;

      .option {
        margin: 2vh 0;

        label {
          font-size: 16px;
          line-height: 14px;
        }
      }
    }
  }

  .mobileFooter {
    position: fixed;
    display: block;
    bottom: 0;
    width: 100%;
    padding: 24px 10px;
    box-shadow: 0px -4px 10px rgba(211, 211, 211, 0.25);
  }
}
