@import 'constants';
@import 'mixins';

.container {
  background-color: $rove-orange;
  flex: 1;
  display: flex;
  flex-direction: row;

  @include media('<=tablet') {
    margin: 1vh 3vw;
    flex-direction: column;
  }

  .imageContainer {
    flex: 1;
    position: relative;

    @include media($sm...) {
      aspect-ratio: 1;
    }
  }

  .contentContainer {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin: 160px 0;

    @include media($sm...) {
      margin: 0;
      justify-content: flex-end;
    }

    .body {
      margin: 0 7vw 0 0;

      @include media($sm...) {
        margin: 2vh 8vw 0 0;
      }
      @include media($md...) {
        margin: 0 5vw;
      }
      @include media($lg...) {
        margin: 0 5vw;
      }
      @include media($xl...) {
        margin: 0 5vw;
      }

      .header {
        display: flex;
        flex-direction: row;

        .profilePictureContainer {
          position: relative;
          height: 138px;
          width: 138px;

          @include media($sm...) {
            height: 92px;
            width: 92px;
          }
        }

        .info {
          flex: 10;
          display: flex;
          flex-direction: column;
          justify-content: flex-end;
          padding-bottom: 1vh;
          padding-left: 34px;

          .name {
            font-size: 24px;
            line-height: 25px;
          }
          .company {
            font-weight: 300;
            font-size: 14px;
            line-height: 25px;
          }
        }
      }

      .content {
        display: flex;
        justify-content: center;

        .description {
          background-color: $rove-black;
          color: white;
          padding: 34px;

          .ratingContainer {
            min-height: 18px;
            font-size: 12px;
          }

          .locationName {
            font-size: 18px;
            line-height: 25px;

            &:hover {
              text-decoration: underline;
            }
          }

          .review {
            padding-top: 2vh;
            font-weight: 300;
            font-size: 14px;
            line-height: 21px;

            @include media($sm...) {
              display: -webkit-box;
              -webkit-line-clamp: 5;
              -webkit-box-orient: vertical;
              overflow: hidden;

              &.readMore {
                -webkit-line-clamp: unset !important;
              }
            }
          }

          .expandText {
            display: none;

            @include media($sm...) {
              display: unset;
              padding-top: 2vh;
              cursor: pointer;
              transition: 0.2s linear all;
              font-size: 12px;

              &:hover {
                opacity: 0.7;
              }
            }
          }

          @include media($sm...) {
            padding: 3vh 8vw;
          }
        }

        .arrows {
          align-self: flex-end;
          display: flex;
          flex-direction: column;

          @include media($sm...) {
            align-self: flex-start;
          }

          .arrow {
            height: 80px;
            width: 80px;
            padding: 25px;
            font-size: 23px;
            stroke-width: 0.75px;
            border: 1px solid black;
            border-left: 0;

            &:last-child {
              border-top: 0;
            }
            &:hover {
              cursor: pointer;
            }

            @include media($sm...) {
              stroke-width: 1px;
            }
          }
        }
      }
    }
  }
}
