@import 'constants';
@import 'mixins';

.container {
  display: flex;
  flex-direction: column;
  font-weight: 300;
  justify-content: center;
  position: relative;
  align-items: normal;

  @include media($sm...) {
    padding: 1vh 2vw 2vh 2vw;
    flex-direction: column;
    border: 0.5px solid $rove-green;
    align-items: flex-start;
    background-color: change-color($color: #ffffff, $alpha: 0.5);
  }

  .icon {
    margin-right: -20px;
  }

  .placeholder {
    font-size: 12px;
    color: $rove-tan;
    font-weight: 300;
  }

  .button {
    display: flex;
    align-items: center;
    justify-content: center;
    white-space: nowrap;
    height: 100%;

    @include media($sm...) {
      justify-content: start;
      align-items: flex-start;
      flex-direction: column;
      width: 100%;
      padding: 1vh 2vw 0 2vw;
    }

    &:hover {
      cursor: pointer;
    }

    .label {
      display: flex;
      font-weight: 300;
      font-size: 12px;
      line-height: 14px;
      margin-bottom: 1vh;
    }

    .dates {
      font-weight: 500;
      font-size: 14px;
      line-height: 14px;
    }

    .arrow {
      margin: 0 1vw;
    }

    .dateButton {
      height: 100%;
      display: flex;
      align-items: center;
      padding: 0 1vw;
      min-width: 115px;
      justify-content: center;
    }

    .borderButton {
      border: 1px solid black;
      background: rgba(255, 255, 255, 0.5);
    }
  }

  .calendarContainer {
    position: absolute;
    top: 50px;
    z-index: $calendar-z-index;
    left: calc(50% - 135px);

    .calendar {
      background-color: #fcfaf8;
      border: 1px solid $rove-black;
      border-radius: 0;
    }
  }

  .footer {
    display: flex;
    justify-content: space-between;

    .footerSpan {
      padding: 1rem 2rem;
      color: $rove-blue;
      font-weight: 500;
      font-size: 14px;

      &:hover {
        cursor: pointer;
      }
    }
  }
}

.secondary {
  @include media($sm...) {
    background: rgba(248, 244, 241, 0.5);
    border-top: none;
  }

  .label,
  .dates {
    color: $rove-black;
    font-weight: 600 !important;
  }

  .button {
    color: $rove-black;
    font-weight: 300;

    .placeholder {
      color: $rove-black;
    }

    svg {
      path {
        stroke: $rove-black;
      }
    }
  }
}
