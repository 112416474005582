@import 'constants';

.container {
  display: flex;
  height: 44px;
  transition: 250ms;

  .button {
    flex: 1;
    background: $rove-blue;
    border: 1px solid $rove-black;
    font-weight: 300;
    font-family: Poppins;
    color: $rove-black;
    padding: 0 3vw;
    transition: 250ms;

    span {
      white-space: nowrap;
    }
  }

  &.disabled {
    opacity: 0.5;

    *:hover {
      color: $rove-black !important;
      stroke: $rove-blue !important;
      background-color: $rove-blue !important;
      cursor: auto;
    }
  }
}

.container > *:hover {
  color: $rove-white;
  stroke: $rove-white;
  background-color: $rove-black;
  cursor: pointer;
}
