@import 'constants';
@import 'mixins';

.userHeaderButtonContainer {
  margin-left: 25px;
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;

  .profilePicture {
    position: relative;
    height: 33px;
    width: 33px;
    overflow: hidden;
    border-radius: 100px;
  }

  .blankImage {
    background-color: #c4c4c4;
  }

  .label {
    margin: 0 10px;
  }
}
