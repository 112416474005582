@import 'constants';
@import 'mixins';

.container {
  display: flex;
  flex-direction: row;
  flex: 1;
  position: relative;

  .count {
    position: absolute;
    bottom: 3vh;
    right: 10vw;
    font-size: 14px;
    letter-spacing: 2px;

    b {
      font-weight: 500;
      font-size: 24px;
    }

    @include media($sm...) {
      bottom: 5vh;
      right: 13vw;
    }
    @include media($md...) {
      right: 5vw;
    }
    @include media($lg...) {
      right: 7vw;
    }
    @include media($xl...) {
      right: 10vw;
    }
  }
}
