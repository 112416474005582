.container {
  display: flex;
  flex-direction: row;
  overflow: auto;
  flex: 1;
  height: 100%;

  &::-webkit-scrollbar {
    display: none;
  }
}
