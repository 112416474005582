@import 'constants';
@import 'mixins';

.profileHeaderMenuContainer {
  position: absolute;
  background-color: $rove-white;
  top: 65px;
  border: 1px solid $rove-dark-black;
  box-shadow: 0px 10px 10px rgba(182, 182, 182, 0.25);
  z-index: $menu-z-index;
  width: fit-content;
  padding: 10px 0;

  display: none;
  flex-direction: column;

  .divider {
    border-bottom: 0.5px solid $rove-green;
    margin: 5px 0;
  }

  .buttonContainer {
    margin: 10px 0;
    padding: 0 24px;
  }

  span, a {
    font-weight: 300;
    font-size: 14px;
    line-height: 14px;
    color: black !important;

    &:hover {
      cursor: pointer;
    }
  }

  &.open {
    display: flex;
  }
}
