@import 'constants';

.mobileContainer {
    position: fixed;
    width: 100%;
    background-color: $rove-off-white;
    top: 0;
    bottom: 0;
    left: 0;
    z-index: $menu-z-index;

    .header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin: 2vh 3vw;

        .backButton {
            display: flex;
            font-size: 12px;
            line-height: 12px;
            font-weight: 300;

            span {
                display: flex;
                align-items: center;
            }
        }

        .clearButton {
            font-size: 12px;
            line-height: 14px;
            color: $rove-light-blue;
        }
    }

    .body {
        .week {
            display: flex;
            justify-content: center;
            padding: 1vh 0;
            border-bottom: 0.5px solid $rove-green;

            .day {
                width: 50px;
                text-align: center;
                font-weight: 300;
                font-size: 10px;
                line-height: 14px;
            }
        }

        .list {
            height: 75vh;
            overflow: auto;

            .month {
                margin: 1vh 0;

                .header {
                    margin: 3vh 0 1vh 7vw;
                }

                .days {
                    display: grid;
                    grid-template-columns: 50px 50px 50px 50px 50px 50px 50px;
                    grid-template-areas: 'day day day day day day day';
                    justify-content: center;
                    align-items: center;

                    .day {
                        height: 50px;
                        width: 50px;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        font-weight: 300;
                        font-size: 10px;
                        line-height: 14px;
                    }

                    .today {
                        font-weight: 500;
                        position: relative;

                        &:after {
                            content: '';
                            position: absolute;
                            bottom: 0.2em;
                            display: block;
                            width: 0.6em;
                            height: 1px;
                            background: #000;
                            left: 50%;
                            opacity: 0.5;
                            transform: translateX(-50%);
                            transition: 0.2s;
                        }
                    }

                    .border {
                        border: 0.5px solid #d6d6d6;
                    }

                    .selected {
                        background-color: rgba(112, 197, 193, 0.2);
                    }

                    .limit {
                        background-color: #70c5c1;
                    }
                }
            }
        }
    }

    .footer {
        position: fixed;
        display: block;
        bottom: 0;
        width: 100%;
        padding: 24px 10px;
        box-shadow: 0px -4px 10px rgba(211, 211, 211, 0.25);
    }
}

.calendarError {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 12px;
    color: $rove-white;
    background: $rove-orange;
    padding: 4px 11px;
    width: calc(100% - 30px);
    box-sizing: border-box;
    margin: 0 15px 20px 15px;

    .errorIcon {
        margin-right: 9px;
        width: 13px;
        min-width: 13px;
        height: 13px;
    }
}

.disabled {
    background-color: #ececec;
}

.container {
    width: 275px;
    background-color: #fcfaf8;
    z-index: $menu-z-index;
    box-shadow: 0px 10px 10px 10px rgba(182, 182, 182, 0.25);
    border: 1px solid #000000;

    .header {
        margin: 30px 15px 15px 15px;
        font-weight: 300;
        font-size: 14px;
        line-height: 14px;
        display: flex;
        justify-content: space-between;
    }

    .body {
        .week {
            display: flex;
            justify-content: center;

            .day {
                width: 35px;
                height: 15px;
                text-align: center;
                font-weight: 300;
                font-size: 10px;
                line-height: 14px;
            }
        }

        .list {
            display: flex;
            overflow-x: auto;
            scroll-snap-type: y mandatory;
            flex-flow: row nowrap;

            &::-webkit-scrollbar {
                display: none;
            }

            .month {
                margin: 1vh 15px;

                .header {
                    outline: 1px solid red;
                    text-align: center;
                }

                .days {
                    display: grid;
                    grid-template-columns: 35px 35px 35px 35px 35px 35px 35px;
                    grid-template-areas: 'day day day day day day day';
                    justify-content: center;
                    align-items: center;

                    .day {
                        width: 35px;
                        height: 35px;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        font-weight: 300;
                        font-size: 10px;
                        line-height: 14px;
                    }

                    .today {
                        font-weight: 500;
                        position: relative;

                        &:after {
                            content: '';
                            position: absolute;
                            bottom: 0.2em;
                            display: block;
                            width: 0.6em;
                            height: 1px;
                            background: #000;
                            left: 50%;
                            opacity: 0.5;
                            transform: translateX(-50%);
                            transition: 0.2s;
                        }
                    }

                    .disabled {
                        color: #d6d6d6;
                    }

                    .border {
                        border: 0.5px solid #d6d6d6;
                    }

                    .selected {
                        background-color: rgba(112, 197, 193, 0.2);
                    }

                    .limit {
                        background-color: #70c5c1;
                    }
                }
            }
        }
    }

    .footer {
        display: flex;
        justify-content: space-between;
        margin: 0 15px 20px 15px;

        .footerSpan {
            flex: unset;
            color: $rove-blue;
            font-weight: 500;
            font-size: 14px;
        }
    }
}
