@import 'constants';
@import 'mixins';

.listingInfoCardContainer {
  margin: 2vh 3vw;
  box-sizing: border-box;

  &:hover {
    cursor: pointer;
  }

  @include media($sm...) {
    width: 280px;
  }

  .header {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-bottom: 1vh;

    .title {
      font-size: 14px;
      line-height: 18px;
      font-weight: 300;
    }

    .secondRow {
      display: flex;
      justify-content: space-between;

      @include media($sm...) {
        margin-top: 6px
      }

      .subtitle {
        font-size: 12px;
        font-weight: 300;
      }

      .rating {
        display: flex;
        align-items: center;
        color: $rove-blue;
        font-weight: 500;
        font-size: small;

        svg {
          margin-right: 5px;
          font-size: large;
        }
      }
    }
  }

  .body {
    width: 390px;
    margin-bottom: 1vh;

    @include media($sm...) {
      width: 100%;
    }
  }

  .footer {
    margin-top: 1vh;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .price {
      font-weight: 300;
      font-size: 12px;
      line-height: 14px;

      @include media($sm...) {
        max-width: 80px;
        overflow-wrap: break-word;
      }
    }

    .features {
      display: flex;
      font-size: 10px;
      color: $rove-black;

      .feature {
        display: flex;
        flex-direction: column;
        margin: 0 5px;

        span {
          font-size: 12px;
          text-align: center;
        }

        @include media($sm...) {
          text-align: right;
          span {
            text-align: right;
          }
        }
      }
    }
  }
}
