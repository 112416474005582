@import 'constants';
@import 'mixins';

.tabButton {
  padding: 0 35px;
  font-weight: 300;
  color: change-color($color: $rove-black, $alpha: 0.5);
  text-align: center;
  display: flex;
  font-size: 14px;
  line-height: 14px;

  @include media($sm...) {
    padding: 0 18px;
  }

  .text {
    flex: 1;
    white-space: nowrap;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    border-bottom: 4px solid transparent;

    &.selected {
      border-bottom: 4px solid $rove-blue;
    }

    .label {
      flex: 1;

      &::after {
        display: block;
        content: attr(data-title);
        font-weight: 600;
        height: 0;
        overflow: hidden;
        visibility: hidden;
      }
    }
  }

  &:hover {
    font-weight: 400;
    cursor: pointer;
  }
}

.selected {
  color: $rove-black;
}

.tabDropdown {
  position: absolute;
  background-color: $rove-white;
  top: 64px;
  border: 1px solid #000000;
  box-shadow: 0 10px 10px rgba(182, 182, 182, 0.25);
  z-index: $menu-z-index;
  width: fit-content;
  padding: 10px 0;
  display: none;
  flex-direction: column;

  span, a {
    padding: 10px 24px;
    font-weight: 300;
    font-size: 14px;
    line-height: 14px;
    color: black !important;
    text-align: left;

    p {
      min-width: 150px;
      margin: 0;
      font-weight: 600;
      font-size: 12px;
      line-height: 14px;
      letter-spacing: 1px;
      color: $rove-blue!important;
    }

    &:hover {
      background-color: rgba($rove-green, 0.1);
      cursor: pointer;
    }
  }

  &.open {
    display: flex;
  }
}
