@use "sass:map";

@import 'mixins';
@import 'constants';

.container {
  background-color: $rove-off-white;
  position: relative;

  .firstContainer {
    display: flex;
    flex-direction: column;
    background-image: linear-gradient(180deg, rgba(248, 244, 241, 0.46) 0%, #F8F4F1 100%, #F8F4F1 100%),
      url(/images/background.png);

    background-color: $rove-off-white;
    background-size: cover;
    background-repeat: no-repeat;

    @include media($sm...) {
      background-image: linear-gradient(180deg, rgba(248, 244, 241, 0.7) 0%, #F8F4F1 100%),
        url('/images/background-mobile.png');
      background-size: 100vw 60vh;
    }

    .firstSection {
      margin-top: 10vh;
      padding: 0 7vw 10vh 7vw;

      @include media($sm...) {
        padding: 0 7vw 2vh 7vw;
      }
    }

    .title {
      font-family: Poppins, serif;
      font-weight: 400;
      font-size: 70px;
      line-height: 75px;
      color: $rove-black;
      margin: 100px 0 44px;

      @include media($sm...) {
        font-size: 30px;
        line-height: 35px;
        margin: 40px 0 12px;
      }
    }
    .subtitle {
      font-family: Poppins, serif;
      font-weight: 500;
      font-size: 16px;
      line-height: 30px;
      color: $rove-black;
      margin: 3vh 0 5vh 0;
      max-width: 360px;

      @include media($sm...) {
        font-size: 14px;
        line-height: 18px;
        max-width: 310px;
        margin: 12px 0 26px 0;
      }
    }

    .marketDateSelectorContainer {
      margin-top: 3vh;
      height: 80px;
      background: rgba(248, 244, 241, 0.5);
      border: 0.5px solid $rove-green;

      @include media($sm...) {
        margin-bottom: 3vh;
        height: auto;
        background-color: transparent;
        border: none;
      }
    }

    .propertiesByLocationContainer {
      span {
        color: $rove-black;

        @include media($sm...) {
          color: inherit;
        }
      }
    }
  }

  .secondContainer {
    padding-right: 0;
    padding-left: 1vw;
    padding-bottom: 100px;

    @include media($sm...) {
      padding: 0;
    }

    .title {
      text-align: center;
      font-size: 70px;
      font-weight: 400;

      @include media($sm...) {
        margin-top: 3vh;
        font-size: 30px;
      }
    }

    .selector {
      display: flex;
      flex-direction: row;
      margin: 3vh 0;
      justify-content: center;

      .selectorTitle {
        writing-mode: tb-rl;
        transform: rotate(-180deg);
        text-align: right;
        font-weight: 100;
        word-spacing: 10px;
        letter-spacing: 5px;
        margin-top: 5vh;

        @include media($sm...) {
          display: none;
        }
      }

      .roveDifferenceSelector {
      }
    }
  }

  .thirdContainer {
    @include media($sm...) {
      padding: 0 3vw;
    }

    .workspacesDescription {
      background-color: $rove-green;
      display: flex;
      flex-direction: row;
      color: $rove-off-white;
      flex-wrap: wrap;

      .leftSide {
        flex: 1;
        position: relative;

        @include media($sm...) {
          flex: 2;
          min-height: 246px;
        }
      }

      .rightSide {
        flex: 1;
        padding: 0 0 5vh 0;

        @include media($sm...) {
          padding: 6vh 4vw;
          flex-basis: map.get($breakpoints, 'phone');
        }

        .title {
          font-size: 70px;
          line-height: 80px;
          margin-left: 5vw;
          padding: 15vh 5vw 5vh 5vw;
          display: block;
          border-left: 1px solid $rove-off-white;

          @include media($sm...) {
            font-size: 20px;
            line-height: 30px;
            padding: 0;
            border: 0;
            max-width: 300px;
          }
        }

        .description {
          font-size: 16px;
          line-height: 24px;
          display: block;
          padding: 5vh 20vw 100px 2.5vw;
          font-weight: 500;

          @include media($sm...) {
            margin: 2vh 10vw 2vh 5vw;
            font-size: 12px;
            line-height: 16px;
            padding: 0;
            border: 0;
          }
        }
      }
    }
  }

  .fourthContainer {
    font-size: 50px;
    line-height: 70px;
    padding: 15vh 20vw;

    @include media($sm...) {
      font-size: 30px;
      line-height: 30px;
      padding: 8vh 4vw;
    }
  }

  .fifthContainer {
    @include media($sm...) {
      padding: 0 3vw;
    }

    .workspacesDescription {
      background-color: $rove-blue;
      display: flex;
      flex-direction: row-reverse;
      color: $rove-black;
      flex-wrap: wrap;

      .leftSide {
        flex: 1;
        position: relative;

        @include media($sm...) {
          flex: 2;
          min-height: 246px;
        }
      }

      .rightSide {
        flex: 1;
        padding: 0 0 5vh 0;

        @include media($sm...) {
          padding: 6vh 4vw;
          flex-basis: map.get($breakpoints, 'phone');
        }

        .title {
          font-size: 70px;
          line-height: 80px;
          margin-right: 5vw;
          padding: 15vh 5vw 5vh 5vw;
          display: block;
          border-bottom: 1px solid $rove-black;

          @include media($sm...) {
            font-size: 20px;
            line-height: 30px;
            margin-left: 5vw;
            padding: 0;
            border: 0;
          }
        }

        .description {
          font-size: 16px;
          line-height: 24px;
          display: block;
          padding: 5vh 5vw 100px 15vw;
          font-weight: 500;
          min-height: 215px;

          @include media($sm...) {
            margin: 2vh 10vw 2vh 5vw;
            min-height: unset;
            font-size: 12px;
            line-height: 16px;
            padding: 0;
            border: 0;
          }
        }
      }
    }
  }

  .sixthContainer {
    padding: 15vh 0;

    @include media($sm...) {
      padding: 5vh 0 8vh;
    }

    .title {
      margin: 2vh 0 0 0;
      text-align: center;
      font-size: 70px;
      line-height: 50px;

      @include media($sm...) {
        font-size: 30px;
        line-height: 30px;
      }
    }

    .sliderContainer {
      margin: 10vh 0;
      margin-left: 5vw;
      margin-right: 1vw;

      @include media($sm...) {
        margin: 0;

        &::-webkit-scrollbar {
          display: none;
        }
      }

      &::-webkit-scrollbar {
        height: 6px;
      }

      &::-webkit-scrollbar-thumb {
        border: 3px solid #f0beaa;
      }

      &::-webkit-scrollbar-track {
        margin: 0 1vw;
        background: rgba(240, 190, 170, 0.3);
      }
    }

    .button {
      font-size: 16px;
      line-height: 14px;
      justify-content: center;
      align-items: center;
      font-weight: 300;

      @include media($sm...) {
        font-size: 14px;
      }
    }
  }

  .seventhContainer {
    min-height: 60vh;
    display: flex;

    .reviewListContainer {
    }
  }
}

.header {
  display: flex;
  flex-direction: row;
  position: absolute;
  border-bottom: 0.5px solid $rove-green;
  background-color: transparent;
  width: 100%;
  max-width: 1600px;
  top: 0;
  padding: 1vh 7vw 0 7vw;
  z-index: 1;

  button > span {
    mix-blend-mode: unset;
    color: unset;
  }
  span {
    color: $rove-dark-black;
  }
  svg {
    color: $rove-dark-black;
  }
}
