@import 'constants';

.open {
  display: flex;
  flex-direction: column;
  width: 100vw;
  height: 100vh;
}

.closed {
  display: none;
}

.menu {
  position: absolute;
  background: rgba(170, 212, 210, 0.9);
  backdrop-filter: blur(10px);
  padding: 2vh 3vw;
  left: 0;
  z-index: $menu-z-index;

  .container {
    display: flex;
    flex: 1;
    flex-direction: column;
    justify-content: space-between;
    padding: 7vh 0;

    .header {
      flex: 0;

      .closeButton {
        display: flex;
      }
    }

    .body {
      .buttonText {
        padding: 2.5vh 3vw;
        font-size: 20px;

        & > span {
          width: 100%;
        }
      }
    }

    .footer {
      .buttonText {
        padding: 2.5vh 3vw;
        font-size: 30px;
        line-height: 30px;
      }
    }

    .hostButton {
      width: 95%;
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    .hostDropdown {
      margin: 0 0 20px 20px;
      display: flex;
      flex-flow: column;
      row-gap: 25px;
    }
  }
}
