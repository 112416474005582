@import 'constants';
@import 'mixins';

.headerContainer {
  display: flex;
  flex-direction: row;
  padding: 1vh 1vw 0vh 1vw;
  border-bottom: 0.5px solid $rove-green;
  height: $header-height;

  @include media('<=tablet') {
    padding: 2vh 3vw 0vh 2vw;
    overflow: hidden;
  }

  @include media($sm...) {
    padding: 2vh 3vw 1vh 2vw;
    overflow: hidden;
  }

  .logoContainer {
    flex: 1;
    display: flex;
    align-items: center;

    .searchBoxContainer {
      margin: 35px;
      max-width: 250px;
    }
  }

  .menuIconContainer {
    display: flex;
    align-items: center;
  }

  .actionsContainer {
    display: flex;
    align-items: center;
    flex-direction: row;

    .headerTabs {
      div {
        color: $rove-dark-black!important;
      }
    }
  }

  .buttonContainer {
    min-height: 45px;
  }
}

.hostButton {
  width: max-content;
  display: flex;
  align-items: center;
  column-gap: 8px;
}
