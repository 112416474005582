@import 'constants';
@import 'mixins';

.container {
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid white;
  padding: 10px;
  position: relative;

  @include media($sm...) {
    border: 0;
    flex-direction: column;
    padding: 0;
  }

  .selectorContainer {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    flex: 1;

    @include media($sm...) {
      width: 100%;
    }

    .icon {
      flex: 1;
      text-align: right;
    }

    .selector {
      flex: 2;

      @include media($sm...) {
        width: 100%;
      }
    }
  }

  .dividerContainer {
    flex: none;
    display: flex;
    justify-content: center;

    .divider {
      border-right: 0.5px solid $rove-green;
      position: absolute;
      height: 70%;
      top: 10px;
    }
  }

  .selectorContainer {
    flex: 1;
  }

  .dateRangeContainer {
    flex: 1.5;
    font-weight: 200;
    font-size: 12px;
    line-height: 14px;

    svg {
      font-size: 20px;
      fill: $rove-black;

      &:first-child {
        stroke-width: 0;
      }
    }

    @include media($sm...) {
      flex: none;
      width: 100%;
    }
  }

  .searchButton {
    flex: 0.5;
    height: 100%;
    width: 170px;

    button {
      border: none;

      span {
        font-size: 12px;
      }
    }

    @include media($sm...) {
      flex: none;
      margin-top: 12px;
      width: 100%;
      height: 50px;
    }
  }
}
