@import 'constants';

.inputContainer {
  display: flex;
  flex-direction: row;
  border: 1px solid $rove-green;
  padding: 0.5vh 0.5vw;
  margin: 0 1vw;
  background-color: change-color($color: #ffffff, $alpha: 0.5);

  &.inputContainerFocused {
    border: 1px solid $rove-blue;
  }

  .icon {
    display: flex;
    align-items: center;
    flex: 0;
  }

  .input {
    flex: 1;
    border: none;
    margin-left: 5px;
    font-family: Poppins;
    font-size: 18px;
    background-color: change-color($color: #ffffff, $alpha: 0.5);
    width: 100%;

    &::placeholder {
      color: $rove-tan;
      background-color: transparent;
      font-weight: 300;
    }
  }
}
