@import 'constants';
@import 'mixins';

.container {
  ::-webkit-scrollbar {
    display: none;
  }
  scrollbar-width: none;
  margin-bottom: 5vw;

  .tabsContainer {
    margin-left: 5vw;
    margin-bottom: 2vh;
    min-height: 32px;

    @include media($sm...) {
      margin-left: 0;
    }
  }

  .propertiesContainer {
    @include media($sm...) {
      margin-left: 0;
    }
  }

  .button {
    justify-content: flex-end;
    margin-right: 5vw;
    margin-top: 2vw;
    font-weight: 300;
  }
}
