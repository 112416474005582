@import 'constants';
@import 'mixins';

.container {
  display: flex;
  flex: auto;
  font-family: Poppins;
  color: $rove-black;

  @include media('>tablet') {
    &:hover {
      cursor: pointer;
      border: 1px solid $rove-light-blue;
      color: $rove-light-blue;
    }
  }
}
