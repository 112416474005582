@import 'constants';

.buttonLoader {
  margin-top: 4px;
  display: inline-block;
  position: relative;
  width: 24px;
  height: 24px;
}
.buttonLoader div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 20px;
  height: 20px;
  margin: 2px;
  border: 2px solid $rove-white;
  border-radius: 50%;
  animation: buttonLoader 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: $rove-white transparent transparent transparent;
}
.buttonLoader div:nth-child(1) {
  animation-delay: -0.45s;
}
.buttonLoader div:nth-child(2) {
  animation-delay: -0.3s;
}
.buttonLoader div:nth-child(3) {
  animation-delay: -0.15s;
}
@keyframes buttonLoader {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.forContent {
  width: 48px;
  height: 48px;
  & > div {
    width: 40px;
    height: 40px;
    border: 4px solid $rove-green;
    border-color: $rove-green transparent transparent transparent;
  }
}
