.container {
  span {
    font-weight: 500;
    font-size: 10px;
    line-height: 20px;
    color: #70c5c1;
    display: flex;

    .star {
      font-size: 18px;
    }
  }

  .description {
    margin-left: 5px;
  }
}
