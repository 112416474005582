@use "sass:map";

@import 'constants';
@import 'mixins';

.container {
  min-width: calc(100% - 4vw - 25px);
  margin: 0 0 0 4vw;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  @include media($sm...) {
    min-width: unset;
    margin: 0;
  }

  .optionsContainer {
    border-left: 0.5px solid $rove-green;
    border-right: 0.5px solid $rove-green;

    @include media($sm...) {
      border-left: 0;
      flex: 1;
    }

    .option {
      margin: 0 2vw;
      padding: 4vh 2vw;
      font-weight: 400;
      text-align: center;
      border-bottom: 0.5px solid $rove-green;
      color: change-color($color: $rove-black, $alpha: 0.5);

      @include media($sm...) {
        padding: 20px 0;
        margin: 0;
        font-size: 10px;
      }

      &:last-child {
        border-bottom: 0;
      }

      &:hover {
        cursor: pointer;
      }

      .optionText {
        display: block;
      }
    }

    .selectedOption {
      color: $rove-black;
    }
  }

  .optionImage {
    position: relative;
    flex: 1;
    margin: 0 1vw;

    @include media($sm...) {
      flex: 2;
    }
  }

  .optionDescription {
    flex: 1;
    border-left: 0.5px solid $rove-green;

    @include media($sm...) {
      flex-basis: map.get($breakpoints, 'phone');
    }

    .descriptionContainer {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      height: 100%;
      padding: 0 4vw;

      @include media($sm...) {
        padding: 50px 4vw;
      }

      .icon {
        font-size: 30px;
      }

      .title {
        font-weight: 400;
        font-size: 10px;
        line-height: 50px;
        text-transform: uppercase;
      }

      .description {
        text-align: center;
        font-weight: 100;
        font-size: 20px;
        line-height: 35px;

        @include media($sm...) {
          font-size: 14px;
          line-height: 20px;
        }
      }

      .button {
        margin: 2vh 1vh 0;
        font-weight: 200;
        font-size: 12px;
        align-items: center;

        @include media($sm...) {
          font-size: 14px;
        }
      }
    }
  }
}
