@import 'constants';
@import 'mixins';

.container {
  display: flex;
  flex-direction: column;
}

.backgroundImage {
  background-image: linear-gradient(
      180deg,
      rgba(255, 255, 255, 0.7) 0%,
      rgba(255, 255, 255, 0.5) 77.6%,
      #f8f4f1 100%
    ),
    url('/images/background.png');
  background-repeat: no-repeat;
  background-size: 100vw auto;
  z-index: $background-image-z-index;

  @include media($sm...) {
    background-position: top center;
    background-size: auto 35vh;
  }
}
