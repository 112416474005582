@import 'constants';
@import 'mixins';

.container {
  display: flex;
  flex-direction: row;
  align-items: flex-end;

  overflow-x: scroll;

  &::-webkit-scrollbar {
    height: 6px;
  }

  &::-webkit-scrollbar-thumb {
    border: 3px solid #f0beaa;
  }

  &::-webkit-scrollbar-track {
    margin: 0 1vw;
    background: rgba(240, 190, 170, 0.3);
  }
}
