@import 'constants';
@import 'mixins';

.container {
  background-color: $rove-off-white;
  padding: 70px 5vw;
  color: $rove-black;

  .body {
    display: flex;
    flex-direction: row;
    padding: 80px 0;
    border-bottom: 0.5px solid $rove-green;
    border-top: 0.5px solid $rove-green;
    justify-content: space-between;

    @include media($sm...) {
      flex-direction: column;
      border: none;
      padding: 32px 0 16px;
    }

    .icons {
      flex: 1.25;

      @include media($sm...) {
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        justify-content: space-between;
      }

      .logo {
        flex: auto;
        margin: 0 0 32px 0;
        align-items: center;
        max-width: 85px;

        @include media($sm...) {
          margin: 0;
        }
      }

      .socialIcons {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        column-gap: 14px;
        max-width: 85px;

        .icon {
          font-size: 20px;

          @include media($sm...) {
            font-size: 22px;
          }
        }
      }
    }

    .section {
      display: flex;
      flex-direction: column;
      flex: 1;

      .title {
        font-weight: 600;
        font-size: 10px;
        line-height: 14px;
        margin-bottom: 12px;

        @include media($sm...) {
          font-weight: 600;
          font-size: 14px;
          line-height: 14px;
          margin-top: 32px;
          margin-bottom: 20px;
        }
      }

      span, a {
        font-weight: 300;
        font-size: 12px;
        line-height: 16px;
        margin-bottom: 12px;

        @include media($sm...) {
          font-size: 20px;
          line-height: 22px;
          padding: 5px 0;
        }
      }
    }

    .community {
      flex: 1.5;

      @include media($sm...) {
        margin-top: 3vh;
      }

      .title {
        font-weight: 600;
        font-size: 17px;
        line-height: 14px;
        margin: 0 0 3vh 0;

        @include media($sm...) {
          margin: 32px 0 0;
          font-weight: 600;
          font-size: 20px;
          line-height: 14px;
          padding-bottom: 20px;
        }
      }

      .input {
        span {
          display: none;
        }

        input {
          background: rgba($rove-white, 0.5);
          height: 42px;
          font-family: 'Poppins', sans-serif;
          font-weight: 300;
          font-size: 12px;
        }

        > div {
          display: none;
        }
      }

      .checkboxes {
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        flex-direction: column;
        font-weight: 300;
        font-size: 12px;
        line-height: 14px;

        .inputContainer {
          margin-top: 8px;
          display: flex;
          justify-content: center;
          align-items: center;

          label {
            margin-left: 11px;
            font-size: 12px;
            line-height: 14px;
          }
        }
      }

      .successText {
        font-size: 12px;
        line-height: 14px;
        color: $rove-green;
        text-align: center;
      }

      .button {
        margin-top: 16px;
        height: 42px;

        span {
          @include media($sm...) {
            font-size: 12px;
            line-height: 14px;
          }
        }
      }
    }
  }

  .bottom {
    font-weight: 300;
    font-size: 12px;
    line-height: 16px;
    padding: 1vh 0 2vh 0;
    display: flex;
    justify-content: space-between;
    letter-spacing: 1px;

    @include media($sm...) {
      flex-direction: column;
    }

    .links {
      @include media($sm...) {
        display: flex;
        flex-direction: column;
        margin: 1vh 0;
      }

      span {
        margin: 0 2vw;

        @include media($sm...) {
          margin: 1vh 0;
        }
      }
    }
  }
}
