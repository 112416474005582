$rove-black: #1d1919;
$rove-white: #ffffff;
$rove-off-white: #f8f4f1;
$rove-tan: #c3beb4;
$rove-green: #859485;
$rove-orange: #f0beaa;
$rove-blue: #aad4d2;
$rove-light-blue: #70c5c1;
$rove-dark-black: #000000;

$header-height: 75px;
$content-max-width: 1600px;

$breakpoints: (
  phone: 486px,
  tablet: 768px,
  laptop: 1024px,
);

$sm: '<=phone';
$md: '>phone', '<=tablet';
$lg: '>tablet', '<=laptop';
$xl: '>laptop';

$background-image-z-index: -1;
$calendar-z-index: 1;
$selector-z-index: 2;
$more-filters-z-index: 3;
$menu-z-index: 11;
