@import 'constants';
@import 'mixins';

.carouselWrapper {
  position: relative;

  & > div {
    width: 100%;
    height: 100%;
  }

  .image {
    position: relative;
    aspect-ratio: 1.5;
  }

  .arrowsWrapper {
    position: absolute;
    left: 0;
    bottom: 0;
    width: max-content;
    height: 29px;
    display: flex;
    z-index: 1;

    .arrow {
      width: 45px;
      height: 29px;
      background: rgba(248, 244, 241, 0.5);
      transition: 0.2s linear all;
      display: flex;
      align-items: center;
      justify-content: center;
      border: none;
      padding: 0;
      cursor: pointer;

      &:hover {
        background: rgba(248, 244, 241, 0.3);
      }

      &:disabled {
        opacity: 0.5;
        cursor: not-allowed;

        &:hover {
          background: rgba(248, 244, 241, 0.5);
        }
      }
    }
  }
}
